import { useEffect } from "react"
import { navigate } from "gatsby"

const HomeRedirect = () => {
    // Redirect to main app on page load
    useEffect(() => {
        navigate("https://app.getparatus.com/home")
    }, [])

    return null
}

export default HomeRedirect
